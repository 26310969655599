import React, { useState, useEffect } from 'react';
import './Hero.css';

const Hero = () => {
    const [burger, setBurger] = useState(false);

    useEffect(() => {
        const heroNav = document.querySelector('.hero__nav');
        const heroTitle = document.querySelector('.hero__title');
        const navLogo = document.querySelector('.nav__logo');
        const navMobile = document.querySelector('.hero__nav-mobile');
        const burgerLines = document.getElementsByClassName('burger__line');
        const navLinks = document.getElementsByClassName('nav__link');
        const navMobileLinks = document.getElementsByClassName('nav-mobile__link');

        const navHandler = () => {
            const totalPixelText =
                Number(getComputedStyle(document.querySelector('.hero__nav')).height.slice(0, -2)) +
                Number(getComputedStyle(document.querySelector('.hero__title')).height.slice(0, -2)) +
                Number(getComputedStyle(document.querySelector('.hero__caption')).height.slice(0, -2)) +
                Number(getComputedStyle(document.querySelector('.hero__button')).borderWidth.slice(0, -2)) * 2 +
                document.querySelector('.hero__button').clientHeight;
            const totalPixelsHero = document.getElementById('hero').clientHeight;
            const totalPixelsPortfolio = totalPixelsHero + document.querySelector('.portfolio').clientHeight;
            const totalPixelsServices = totalPixelsPortfolio + document.querySelector('.services').clientHeight;

            if (window.scrollY > (window.innerHeight - totalPixelText) / 5 - 1) {
                heroNav.classList.add('hero__nav--sticky');
                heroTitle.classList.add('hero__title--sticky');
            } else {
                heroNav.classList.remove('hero__nav--sticky');
                heroTitle.classList.remove('hero__title--sticky');
            }

            if ((window.scrollY >= 0 && window.scrollY < totalPixelsHero - 71) || window.scrollY > totalPixelsServices - 71) {
                heroNav.style.backgroundColor = 'rgb(32, 176, 255)';
                navMobile.style.backgroundColor = 'rgb(32, 176, 255)';
                navLogo.style.color = 'black';
                navLogo.style.borderColor = 'black';
                for (let navLink of navLinks) {
                    navLink.style.color = 'black';
                }
                for (let navMobileLink of navMobileLinks) {
                    navMobileLink.style.color = 'black';
                }
                for (let burgerLine of burgerLines) {
                    burgerLine.style.border = '1px solid black';
                }
            } else if (window.scrollY > totalPixelsHero - 71 && window.scrollY < totalPixelsPortfolio - 71) {
                heroNav.style.backgroundColor = 'white';
                navMobile.style.backgroundColor = 'white';
                navLogo.style.color = 'black';
                navLogo.style.borderColor = 'black';
                for (let navLink of navLinks) {
                    navLink.style.color = 'black';
                }
                for (let navMobileLink of navMobileLinks) {
                    navMobileLink.style.color = 'black';
                }
                for (let burgerLine of burgerLines) {
                    burgerLine.style.border = '1px solid black';
                }
            } else if (window.scrollY > totalPixelsPortfolio - 71 && window.scrollY < totalPixelsServices - 71) {
                heroNav.style.backgroundColor = 'black';
                navMobile.style.backgroundColor = 'black';
                navLogo.style.color = 'white';
                navLogo.style.borderColor = 'white';
                for (let navLink of navLinks) {
                    navLink.style.color = 'white';
                }
                for (let navMobileLink of navMobileLinks) {
                    navMobileLink.style.color = 'white';
                }
                for (let burgerLine of burgerLines) {
                    burgerLine.style.border = '1px solid white';
                }
            }
        };

        setInterval(() => navHandler(), 33);
    });

    return (
        <header className="hero" id="hero">
            <nav className="hero__nav nav">
                <a className="nav__logo" href="#hero">
                    A
                </a>
                <div className="nav__burger burger" onClick={() => setBurger(!burger)}>
                    <div className={burger ? 'burger__line burger__line--1--opened' : 'burger__line burger__line--1'}></div>
                    <div className={burger ? 'burger__line burger__line--2--opened' : 'burger__line burger__line--2'}></div>
                    <div className={burger ? 'burger__line burger__line--3--opened' : 'burger__line burger__line--3'}></div>
                </div>
                <div className="nav__links">
                    <a className="nav__link nav__link--portfolio" href="#p">
                        Portfolio
                    </a>
                    <a className="nav__link nav__link--services" href="#s">
                        Services
                    </a>
                    <a className="nav__link nav__link--contact" href="#c">
                        Contact
                    </a>
                </div>
            </nav>
            <nav className="hero__nav-mobile nav-mobile" style={burger ? { display: 'flex' } : { display: 'none' }}>
                <a className="nav-mobile__link nav-mobile__link--portfolio" href="#p">
                    Portfolio
                </a>
                <a className="nav-mobile__link nav-mobile__link--services" href="#s">
                    Services
                </a>
                <a className="nav-mobile__link nav-mobile__link--contact" href="#c">
                    Contact
                </a>
            </nav>
            <h1 className="hero__title">
                Create Engaging
                <br />
                Digital Experiences
            </h1>
            <h2 className="hero__caption">
                Apptasy combines professional experience in software engineering, systems integration, consulting, creative arts and high performance
                architectures to create robust, user focused digital experiences.
            </h2>
            <a className="hero__button" href="#p">
                Our Projects
            </a>
            <span id="p"></span>
        </header>
    );
};

export default Hero;
