import React, { useEffect } from 'react';
import './Portfolio.css';

const Portfolio = () => {
    const openLightbox = e => {
        if (window.innerWidth > 9999) {
            document.querySelector(`.tiles__lightbox--${e.target.className[e.target.className.length - 1]}`).classList.add('visible');
            document.querySelector(`.tiles__lightbox--${e.target.className[e.target.className.length - 1]}`).classList.add('opaque');
            document.querySelector('html').scrollTop =
                document.querySelector('.hero').clientHeight +
                document.querySelector('.portfolio').clientHeight -
                document.querySelector('.hero__nav').clientHeight -
                document.querySelector('.portfolio__tiles').clientHeight;
        }
    };

    const closeLightbox = () => {
        document.querySelector('.opaque').classList.remove('opaque');
        setTimeout(() => document.querySelector('.visible').classList.remove('visible'), 250);
    };

    useEffect(() => {
        const closeLightboxWheel = () => document.querySelector('.opaque') && closeLightbox();
        document.addEventListener('wheel', closeLightboxWheel);
        return () => document.removeEventListener('wheel', closeLightboxWheel);
    });

    return (
        <section className="portfolio" id="portfolio">
            <h1 className="portfolio__title">View our projects</h1>
            <h2 className="portfolio__caption">
                We love starting with a napkin sketch or a two sentence description of an idea, helping clients plan out their concepts by creating
                wireframes and ultimately building and deploying their vision.
            </h2>
            <div className="portfolio__tiles tiles">
                <div className="tiles__tile tile tile1" onClick={openLightbox}>
                    <h3 className="tile__title tile__title--1">
                        Scholastic <span className="tile__title__arrow tile__title__arrow--1">&rarr;</span>
                    </h3>
                    <p className="tile__caption bold tile__caption--1">Mobile</p>
                    <p className="tile__caption tile__caption--1">API</p>
                    <p className="tile__caption tile__caption--1">
                        Backend
                        <br />
                        Integration
                    </p>
                    <p className="tile__caption tile__caption--1">Web Interface</p>
                    <img src="https://www.apptasy.com/images/scholastic.png" alt="scholastic" className="tile__image tile__image--1" />
                </div>
                <div className="tiles__tile tile tile2" onClick={openLightbox}>
                    <h3 className="tile__title tile__title--2">
                        Coller Capital <span className="tile__title__arrow tile__title__arrow--2">&rarr;</span>
                    </h3>
                    <p className="tile__caption bold tile__caption--2">Tablet</p>
                    <p className="tile__caption tile__caption--2">API</p>
                    <p className="tile__caption tile__caption--2">
                        Content
                        <br />
                        Management
                    </p>
                    <img src="https://www.apptasy.com/images/collertablet.png" alt="collertablet" className="tile__image tile__image--2" />
                </div>
                <div className="tiles__tile tile tile3" onClick={openLightbox}>
                    <h3 className="tile__title tile__title--3">
                        Philo <span className="tile__title__arrow tile__title__arrow--3">&rarr;</span>
                    </h3>
                    <p className="tile__caption bold tile__caption--3">Mobile</p>
                    <p className="tile__caption tile__caption--3">
                        3rd Party API
                        <br />
                        Integration
                    </p>
                    <img src="https://www.apptasy.com/images/philo.png" alt="philo" className="tile__image tile__image--3" />
                </div>
                <div className="tiles__tile tile tile4" onClick={openLightbox}>
                    <h3 className="tile__title tile__title--4">
                        Coller Capital <span className="tile__title__arrow tile__title__arrow--4">&rarr;</span>
                    </h3>
                    <p className="tile__caption bold tile__caption--4">Mobile</p>
                    <p className="tile__caption tile__caption--4">
                        Marketing
                        <br />
                        Campaign
                        <br />
                        Collaboration
                    </p>
                    <img src="https://www.apptasy.com/images/collermobile.png" alt="collermobile" className="tile__image tile__image--4" />
                </div>
                <div className="tiles__tile tile tile5" onClick={openLightbox}>
                    <h3 className="tile__title tile__title--5">
                        SYMMYS <span className="tile__title__arrow tile__title__arrow--5">&rarr;</span>
                    </h3>
                    <p className="tile__caption bold tile__caption--5">Web</p>
                    <p className="tile__caption tile__caption--5">
                        Interactive
                        <br />
                        Coursework
                    </p>
                    <p className="tile__caption tile__caption--5">
                        Repository
                        <br />
                        Integration
                    </p>
                    <img src="https://www.apptasy.com/images/symmys.png" alt="symmys" className="tile__image tile__image--5" />
                </div>
                <div className="tiles__tile tile tile6" onClick={openLightbox}>
                    <h3 className="tile__title tile__title--6">
                        Best & Company <span className="tile__title__arrow tile__title__arrow--6">&rarr;</span>
                    </h3>
                    <p className="tile__caption bold tile__caption--6">Web</p>
                    <p className="tile__caption tile__caption--5">UI Design</p>
                    <p className="tile__caption tile__caption--5">
                        Website
                        <br />
                        Development
                    </p>
                    <img src="https://www.apptasy.com/images/bestco.png" alt="bestco" className="tile__image tile__image--6" />
                </div>
                <div className="tiles__lightbox lightbox tiles__lightbox--1">
                    <button className="lightbox__close" onClick={closeLightbox}>
                        X
                    </button>
                </div>
                <div className="tiles__lightbox lightbox tiles__lightbox--2">
                    <button className="lightbox__close" onClick={closeLightbox}>
                        X
                    </button>
                </div>
                <div className="tiles__lightbox lightbox tiles__lightbox--3">
                    <button className="lightbox__close" onClick={closeLightbox}>
                        X
                    </button>
                </div>
                <div className="tiles__lightbox lightbox tiles__lightbox--4">
                    <button className="lightbox__close" onClick={closeLightbox}>
                        X
                    </button>
                </div>
                <div className="tiles__lightbox lightbox tiles__lightbox--5">
                    <button className="lightbox__close" onClick={closeLightbox}>
                        X
                    </button>
                </div>
                <div className="tiles__lightbox lightbox tiles__lightbox--6">
                    <button className="lightbox__close" onClick={closeLightbox}>
                        X
                    </button>
                </div>
            </div>
            <span id="s"></span>
        </section>
    );
};

export default Portfolio;
