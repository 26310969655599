import React from 'react';
import './Services.css';

const Services = () => {
    return (
        <section className="services" id="services">
            <h1 className="services__title">Utilize our expertise</h1>
            <div className="services__list list">
                <div className="list__service service">
                    <div className="service__description description">
                        <h2 className="description__title">App Development</h2>
                        <div className="description__image description__image--app description__image--mobile"></div>
                        <p className="description__caption">
                            Have us create wireframes and specs or start from your own. We can create complete apps, engaging user interfaces, back
                            end infrastructure and APIs to support your apps and more.
                        </p>
                    </div>
                    <div className="description__image description__image--app description__image--desktop"></div>
                </div>
                <div className="list__service service">
                    <div className="description__image description__image--web description__image--desktop"></div>
                    <div className="service__description description">
                        <h2 className="description__title">Web Development</h2>
                        <div className="description__image description__image--web description__image--mobile"></div>
                        <p className="description__caption">
                            From Wix or SquareSpace websites that can be completed in a matter of days to unique, responsively designed, custom
                            websites built for enterprise scalability.
                        </p>
                    </div>
                </div>
                <div className="list__service service">
                    <div className="service__description description">
                        <h2 className="description__title">UI & UX Design</h2>
                        <div className="description__image description__image--design description__image--mobile"></div>
                        <p className="description__caption">
                            Iterate directly with our team to work through your ideas and draw up comprehensive wireframes before spending any money
                            on a complex project.
                        </p>
                    </div>
                    <div className="description__image description__image--design description__image--desktop"></div>
                </div>
                <div className="list__service service">
                    <div className="description__image description__image--growth description__image--desktop"></div>
                    <div className="service__description description">
                        <h2 className="description__title">Growth Marketing</h2>
                        <div className="description__image description__image--growth description__image--mobile"></div>
                        <p className="description__caption">
                            Let us accelerate your company's growth by designing and managing your social media presence, finding prospects and
                            connecting them with your brand.
                        </p>
                    </div>
                </div>
            </div>
            <span id="c"></span>
        </section>
    );
};

export default Services;
