import React from 'react';
import './Contact.css';

const Contact = () => {
    return (
        <footer className="contact" id="contact">
            <div className="contact__information">
                <h1 className="contact__title">
                    Let's create
                    <br />
                    something
                    <br />
                    together!
                </h1>
                <h2 className="contact__caption">
                    Tell us about your big idea.
                    <br />
                    We will reply within one business day.
                </h2>
                <h2 className="contact__caption">
                    Email Us:{' '}
                    <a href="mailto:create@apptasy.com" className="contact__link">
                        create@apptasy.com
                    </a>
                </h2>
            </div>
            <form className="contact__form form" action="contactform.php" method="POST">
                <input type="text" name="name" className="form__input form__input--name" placeholder="Name" aria-label="Name" required />
                <input type="email" name="email" className="form__input form__input--email" placeholder="Email" aria-label="Email" required />
                <input type="text" name="subject" className="form__input form__input--subject" placeholder="Subject" aria-label="Subject" required />
                <textarea
                    name="message"
                    className="form__input form__message"
                    placeholder="Message"
                    aria-label="Message"
                    rows="6"
                    required
                ></textarea>
                <input type="submit" name="submit" className="form__submit" value="Send Message" />
            </form>
        </footer>
    );
};

export default Contact;
