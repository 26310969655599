import React from 'react';
import Hero from './Hero';
import Portfolio from './Portfolio';
import Services from './Services';
import Contact from './Contact';
import './App.css';

const App = () => {
    return (
        <main className="app">
            <Hero />
            <Portfolio />
            <Services />
            <Contact />
        </main>
    );
};

export default App;
